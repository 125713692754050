<template>
    <div class="my-Search">
        <van-sticky>
            <div class="my-Search__search">
                <van-search
                    class="my-search"
                    v-model="value"
                    show-action
                    shape="round"
                    placeholder="请输入马场名称"
                    @search="onSearch"
                    >
                    <template #action>
                        <div @click="onSearch">搜索</div>
                    </template>
                </van-search>
            </div>
        </van-sticky>
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <van-list
                v-model="loading1"
                :finished="finished1"
                @load="onLoad"
                class="my-list"
                >
                <my-item v-for="item in list" :key="item.id">
                    <div class="info" slot="info">
                        <div class="horse" @click.capture="appointment">红骑马场</div>
                        <div class="address"><van-icon name="location" />江西省南昌东湖区</div>
                    </div>
                    <div class="appoitment" slot="appoitment">
                        课程预约
                        <div class="icon">
                            <van-icon name="phone" />
                        </div>
                    </div>
                </my-item>
                <no-more v-show="!loading1"/>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
export default {
    mixins: [ mixinPage ],
    data() {
        return { 
            value: '',
        }
    },
    methods: {
        init() {
            this.initList()
        },
        onSearch() {
            console.log(this.value)
        },
        appointment() {
            this.$router.push('/appointment')
        }
    },
    mounted() {
        this.init()
    }
    
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-Search{
        &__search {
            // margin: 40px 0 20px;
            padding: 0 20px 0 8px;
            background-color: #fff;
            .van-search__action{
                color: $main;
            }
        }
        .my-pull-refresh{
            .my-list{
                margin-top: 10px;
                padding: 0 25px;
            }
        }
    }
</style>